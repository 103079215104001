import { React } from "react";

const Construction = () => {
  return (
    <div class="uc-message">
      <h2> Page under construction...</h2>
    </div>
  );
};

export default Construction;
